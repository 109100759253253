/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LogoDisplay from '../components/logoDisplay';
import ContactPageLayout from '../components/contactPageLayout';
import PropTypes from 'prop-types';
import TopMenu from '../components/topMenu';



const FunPerson = ({ color }) => {
    const subtitleContent = (
        
    <div className="text-md">
      <p>
      You have done your preliminary study and are ready to sample what you’ve learned. You’d like to know more about the system that’s in place that will help you achieve financial and wellness goals. You’ve come to the right place. This platform is being built to support your research. Connect with us and we’ll learn together.
      </p>
    </div>
  );
    
    
  return (
    <Layout to="/connect" label="WHICH IMAGE?" showMenuIcon>
      <SEO title="Vivo Vibe" />
                                      <TopMenu></TopMenu>

            <section className="h-screen w-screen green-bg-img">

      <ContactPageLayout
        color={color}
        title="You're in the right place"
        titleClassName="bg-directions"
                              subTitle={subtitleContent}
        subtitleContent={subtitleContent}
      ></ContactPageLayout>
      </section>
      </Layout>
  );
};

FunPerson.propTypes = {
  color: PropTypes.string,
};

FunPerson.defaultProps = {
  color: 'research',
};
export default FunPerson;
